import { gql } from '@apollo/client';
import { REPLY_FRAGMENT } from './reply-fragments';

export const REVIEW_SHORT = gql`
    fragment ReviewShort on Review {
        id
        stars
        comment
        createdAt
        submittedAt
        providedBy {
            id
            avatarAws
            activeSniffpass
            nameAndInitial
            firstname
            dogs {
                photos {
                    imageAws
                }
            }
            createdAt
        }
        reply {
            ...ReplyFragment
        }
        photos {
            id
            awsHostUrl
            imageAws
        }
        membershipped
        upvotesCount
        upvote
        reservationQuantity
    }
    ${REPLY_FRAGMENT}
`;

export const REVIEW_CITY_SHORT = gql`
    fragment ReviewCityShort on Review {
        id
        stars
        comment
        createdAt
        submittedAt
        providedBy {
            id
            avatarAws
            activeSniffpass
            nameAndInitial
            firstname
            createdAt
        }
        membershipped
        reservationQuantity
    }
`;

export const REVIEW_SINGLE_SHORT = gql`
    fragment ReviewSingleShort on Review {
        id
        stars
        comment
        createdAt
        submittedAt
        providedBy {
            id
            avatarAws
            nameAndInitial
            createdAt
        }
        spot {
            id
            title
            price
            thumbPhoto
            latitude
            longitude
            city
            state
            country
            zip
            spotUrl
        }
    }
`;

export const REVIEW_FRAGMENT = gql`
    fragment Review on Review {
        ...ReviewShort
        privateComment
        dogsPresent
        domesticAnimalsPresent
        fencingSecureEnough
        peoplePresent
        cleanAsExpected
        reactiveComment
        reactiveRecommend
        swimmingPool
        lakeOrPond
        riverStreamOrCreek
        beach
        agilityEquipment
        parkourObstacles
        trail
        indoorPlaySpace
        field
        smallDogFriendly
        dogsEncounters
        dogsEncountersComment
        peopleEncounters
        peopleEncountersComment
        dogsPresentComment
        peoplePresentComment
        domesticAnimalsPresentComment
    }
    ${REVIEW_SHORT}
`;
