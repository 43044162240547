import { gql } from '@apollo/client';
import { USER_SHORT_INFO } from './user-fragments';

export const DOG_SHORT_INFO = gql`
    fragment DogShortInfo on Dog {
        id
        name
        photos {
            id
            imageAws
            __typename
        }
        __typename
    }
`;

export const DOG_INFO = gql`
    fragment DogInfo on Dog {
        ...DogShortInfo
        about 
        birthAt
        weight
        neutered
        gender 
        breeds
        favoriteActivities 

        currentlyFleaPreventative
        bordatellaNextDueAt
        dhppNextDueAt
        leptospirosisNextDueAt
        rabiesNextDueAt
        dominancePreference
        energyLevel
        socializationLevel
        trainingLevel
        playstyleComment
        reactiveComment
        photos {
            imageAws
            ordinal
            id
        }
        files {
            uploadedFile
            ordinal
            id
        }
        owner {
            ...UserShortInfo
        }
    }
    ${DOG_SHORT_INFO}, ${USER_SHORT_INFO}
`;

export const DOG_NAMES_FRAGMENTS = gql`
    fragment DogName on DogName {
        about
        dogname
        id
        rank
        topBreed
        topGender

        cool
        cute
        funny
        unique
    }
`;

const BLOG_AUTHOR_FRAGMENTS = gql`
    fragment Author on Author {
        id
        name
        slug
        photo
        description
        facebookLink
        linkedinLink
        threadLink
        tiktokLink
        youtubeLink
        publicEmail
    }
`;

const BLOG_POST_FRAGMENTS = gql`
    fragment Post on Post {
        id
        slug
        title
        thumbnail
    }
`;

export const DOG_NAMES_PAGE_FRAGMENTS = gql`
    fragment DogNamePage on DogNamePage {
        id
        about
        metaTitle
        metaDescription
        slug
        author {
            ...Author
        }
        chosenFilters {
            gender {
                name
                slug
            }
            breed {
                name
                slug
            }
            state {
                name
                slug
            }
            fancy {
                name
                slug
            }
        }
        dogNames(page: 1, limit: 50) {
            collection {
                ...DogName
            }
            metadata {
                totalPages
            }
        }
        faqs {
            answer
            question
        }
        nextPost {
            ...Post
        }
        previousPost {
            ...Post
        }
        recentPosts {
            ...Post
            descriptionShort
        }
        relatedPosts {
            ...Post
            descriptionShort
        }
        genders {
            gender
            slug
            noFollow
        }
        allTopBreeds {
            breed
            slug
            shortSlug
        }
        topBreeds {
            breed
            slug
            noFollow
        }
        fancies {
            name
            slug
            noFollow
        }
        states {
            name
            slug
            noFollow
        }
    }
    ${DOG_NAMES_FRAGMENTS}
    ${BLOG_AUTHOR_FRAGMENTS}
    ${BLOG_POST_FRAGMENTS}
`;

export const DOG_NAMES_PAGE_V2_FRAGMENTS = gql`
    fragment DogNamePageV2 on DogNamePage {
        id
        about
        metaTitle
        metaDescription
        slug
        thumbnail
        author {
            ...Author
        }
        chosenFilters {
            gender {
                name
                slug
            }
            breed {
                name
                slug
            }
            state {
                name
                slug
            }
            fancy {
                name
                slug
            }
        }
        dogNames(page: 1, limit: 50) {
            collection {
                ...DogName
            }
            metadata {
                totalPages
            }
        }
        coolDogNames(page: 1, limit: 20) {
            collection {
                ...DogName
            }
            metadata {
                totalPages
            }
        }
        cuteDogNames(page: 1, limit: 20) {
            collection {
                ...DogName
            }
            metadata {
                totalPages
            }
        }
        funnyDogNames(page: 1, limit: 20) {
            collection {
                ...DogName
            }
            metadata {
                totalPages
            }
        }
        uniqueDogNames(page: 1, limit: 20) {
            collection {
                ...DogName
            }
            metadata {
                totalPages
            }
        }
        faqs {
            answer
            question
        }
        nextPost {
            ...Post
        }
        previousPost {
            ...Post
        }
        recentPosts {
            ...Post
            descriptionShort
        }
        relatedPosts {
            ...Post
            descriptionShort
        }
        genders {
            gender
            slug
            noFollow
        }
        allTopBreeds {
            breed
            slug
            shortSlug
        }
        topBreeds {
            breed
            slug
            noFollow
        }
        fancies {
            name
            slug
            noFollow
        }
        states {
            name
            slug
            noFollow
        }
    }
    ${DOG_NAMES_FRAGMENTS}
    ${BLOG_AUTHOR_FRAGMENTS}
    ${BLOG_POST_FRAGMENTS}
`;

export const DOG_RESCUEPAGE_FRAGMENTS = gql`
    fragment RescuePage on RescuePage {
        id
        author {
            ...Author
        }
        cities {
            city
            slug
            noFollow
            rescues {
                id
                logo
                rescueName
                bio
                slug
            }
        }
        rescues {
            id
            address
            logo
            rescueName
            bio
            slug
            city
            cityRank
            country
            countryRank
            state
            stateRank
            serviceAreas
            testimonials(page: 1, limit: 2) {
                id
                affiliation
                reviewerName
                testimonial
            }
            specializationRanks
        }
        city
        country
        faqs {
            answer
            question
        }
        metaDescription
        metaTitle
        pageType
        nextPost {
            ...Post
        }
        previousPost {
            ...Post
        }
        recentPosts {
            ...Post
            descriptionShort
        }
        relatedPosts {
            ...Post
            descriptionShort
        }
        slug
        specialization
        specializations {
            noFollow
            specialization
            slug
        }
        state
        states {
            city
            slug
            state
            noFollow
            rescues {
                id
                logo
                rescueName
                bio
                slug
            }
        }
    }
    ${BLOG_AUTHOR_FRAGMENTS}
    ${BLOG_POST_FRAGMENTS}
`;

export const DOG_RESCUE_FRAGMENTS = gql`
    fragment Rescue on Rescue {
        address
        adoptionPolicy
        adoptionWebsite
        author {
            ...Author
        }
        bio
        city
        cityRank
        cities {
            city
            slug
            noFollow
            rescues {
                id
                logo
                rescueName
                bio
                slug
            }
        }
        country
        countryRank
        id
        logo
        mission
        specializations
        otherSpecializations
        specializationsNearby {
            noFollow
            specialization
            slug
        }
        pageType
        publicEmail
        nextPost {
            ...Post
        }
        previousPost {
            ...Post
        }
        recentPosts {
            ...Post
            descriptionShort
        }
        relatedPosts {
            ...Post
            descriptionShort
        }
        rescueName
        serviceAreas
        slug
        state
        stateRank
        states {
            city
            slug
            state
            noFollow
            rescues {
                id
                logo
                rescueName
                bio
                slug
            }
        }
        testimonials(page: 1, limit: 10) {
            id
            affiliation
            reviewerName
            testimonial
        }
        testimonialsCount
        website
        specializationRanks
    }
    ${BLOG_AUTHOR_FRAGMENTS}
    ${BLOG_POST_FRAGMENTS}
`;

export const DOG_TRAINERPAGE_FRAGMENTS = gql`
    fragment TrainerPage on TrainerPage {
        author {
            ...Author
        }
        categories {
            name
            slug
        }
        category
        cities {
            name
            slug
            trainers {
                id
                avatar
                bio
                fullName
                slug
            }
        }
        city
        country
        faqTitle
        faqs {
            answer
            question
        }
        id
        topSectionDesc
        pageType
        nextPost {
            ...Post
        }
        previousPost {
            ...Post
        }
        recentPosts {
            ...Post
            descriptionShort
        }
        relatedPosts {
            ...Post
            descriptionShort
        }
        slug
        state
        states {
            name
            slug
            trainers {
                id
                avatar
                bio
                fullName
                slug
            }
        }
        trainers {
            id
            avatar
            bio
            certifications
            fullName
            serviceAreas
            services
            slug
        }
    }
    ${BLOG_AUTHOR_FRAGMENTS}
    ${BLOG_POST_FRAGMENTS}
`;

export const DOG_TRAINER_FRAGMENTS = gql`
    fragment Trainer on Trainer {
        author {
            ...Author
        }
        avatar
        behavioralIssues
        bio
        certifications
        cities {
            name
            slug
        }
        city
        slug
        city
        country
        email
        favoritePartInBeingTrainer
        firstTrainerTip
        fullName
        id
        metaDescription
        metaTitle
        pageType
        nextPost {
            ...Post
        }
        previousPost {
            ...Post
        }
        recentPosts {
            ...Post
            descriptionShort
        }
        relatedPosts {
            ...Post
            descriptionShort
        }
        serviceAreas
        services
        servicesDetails
        slug
        state
        trainers {
            id
            fullName
            slug
        }
        trainingProcess
        website
        whyDoYouBecomeTrainer

        countryRank
        stateRank
        cityRank

        aggressiveDogTrainingRank
        deafDogTrainingRank
        inHomeTrainingRank
        protectionGuardDogTrainingRank
        reactiveDogTrainingRank
        serviceDogTrainingRank
        boardAndTrainRank
        offLeashTrainingRank
        puppyTrainingRank
        agilityTrainingRank
        herdingTrainingRank

        aggressiveDogTrainingCityRank
        deafDogTrainingCityRank
        inHomeTrainingCityRank
        protectionGuardDogTrainingCityRank
        reactiveDogTrainingCityRank
        serviceDogTrainingCityRank
        boardAndTrainCityRank
        offLeashTrainingCityRank
        puppyTrainingCityRank
        agilityTrainingCityRank
        herdingTrainingCityRank
    }
    ${BLOG_AUTHOR_FRAGMENTS}
    ${BLOG_POST_FRAGMENTS}
`;
