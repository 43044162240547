import { gql } from '@apollo/client';

export const EXTRA_RESERVE_INFO = gql`
    fragment ExtraReserveInfo on Extra {
        id
        title
        price
        description
        fromId
    }
`;

export const EXTRA_INFO = gql`
    fragment ExtraInfo on Extra {
        id
        title
        price
        description
        spotIds
        spots {
            id
        }
    }
`;

export const USER_EXTRAS_INFO = gql`
    fragment UserExtras on User {
        extras {
            ...ExtraInfo
        }
    }
    ${EXTRA_INFO}
`;
