import { gql } from '@apollo/client';
import {
    DOG_NAMES_FRAGMENTS,
    DOG_NAMES_PAGE_FRAGMENTS,
    DOG_NAMES_PAGE_V2_FRAGMENTS,
    DOG_RESCUE_FRAGMENTS,
    DOG_RESCUEPAGE_FRAGMENTS,
    DOG_SHORT_INFO,
    DOG_TRAINER_FRAGMENTS,
    DOG_TRAINERPAGE_FRAGMENTS,
} from './dog-fragments';

export const QUERY_DOGS_LIST = gql`
    query listDogs($ids: [ID]!) {
        dogs(ids: $ids) {
            ...DogShortInfo
            owner {
                id
            }
        }
    }
    ${DOG_SHORT_INFO}
`;

export const QUERY_DOG_NAMES = gql`
    query dogNames($slug: String, $page: Int, $limit: Int, $fancyType: FancyEnum, $sortBy: SortBy, $sortOrder: SortOrder) {
        dogNames(slug: $slug, page: $page, limit: $limit, fancyType: $fancyType, sortBy: $sortBy, sortOrder: $sortOrder) {
            ...DogName
        }
    }
    ${DOG_NAMES_FRAGMENTS}
`;

export const QUERY_DOG_NAMES_PAGE = gql`
    query dogNamePage($slug: String) {
        dogNamePage(slug: $slug) {
            ...DogNamePage
        }
    }
    ${DOG_NAMES_PAGE_FRAGMENTS}
`;

export const QUERY_DOG_NAMES_PAGE_V2 = gql`
    query dogNamePage($slug: String) {
        dogNamePage(slug: $slug) {
            ...DogNamePageV2
        }
    }
    ${DOG_NAMES_PAGE_V2_FRAGMENTS}
`;

export const QUERY_DOG_RESCUES_PAGE = gql`
    query dogRescuePage($slug: String) {
        dogRescuePage(slug: $slug) {
            __typename
            ... on RescuePage {
                ...RescuePage
            }
            ... on Rescue {
                ...Rescue
            }
        }
    }
    ${DOG_RESCUEPAGE_FRAGMENTS}
    ${DOG_RESCUE_FRAGMENTS}
`;

export const QUERY_DOG_RESCUES_TESTIMONIALS = gql`
    query dogRescueTesti($slug: String, $page: Int, $limit: Int) {
        dogRescuePage(slug: $slug) {
            __typename
            ... on Rescue {
                testimonials(page: $page, limit: $limit) {
                    id
                    affiliation
                    reviewerName
                    testimonial
                }
            }
        }
    }
`;

export const QUERY_DOG_TRAINERS_PAGE = gql`
    query dogTrainerPage($slug: String) {
        dogTrainerPage(slug: $slug) {
            __typename
            ... on TrainerPage {
                ...TrainerPage
            }
            ... on Trainer {
                ...Trainer
            }
        }
    }
    ${DOG_TRAINERPAGE_FRAGMENTS}
    ${DOG_TRAINER_FRAGMENTS}
`;
